@import "../../_globalColor";

.footer-text {
  font-size: 0.5em;
  text-align: center;
  color: $subTitle !important;
}

.dark-mode {
  color: $textColorDark !important;
}

.footer-div {
  font-size: 1em;
  margin-top: 2rem;
  padding-bottom: 1rem;
}
