@import "../../_globalColor";

.software-skills-main-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.dev-icons {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.software-skill-inline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px; /* Adjust this value to set the spacing between icons */
  flex-basis: 10%; /* Adjust the percentage to control the number of items per row */
}

.custom-icon {
  width: 70px; /* Original size */
  height: 70px;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    width: 35px; /* Half of the original width */
    height: 35px; /* Half of the original height */
  }

  @media (max-width: 576px) {
    width: 35px; /* Half of the original width */
    height: 35px; /* Half of the original height */
  }
}

.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}

.software-skill-inline:hover > p {
  color: $skillsColor;
}

.software-skill-inline:hover .custom-icon {
  filter: brightness(0.8);
}

/* Adjust flex-basis for smaller screens */
@media (max-width: 1200px) {
  .software-skill-inline {
    flex-basis: 25%; /* 4 items per row */
  }
}

@media (max-width: 992px) {
  .software-skill-inline {
    flex-basis: 33.33%; /* 3 items per row */
  }
}

@media (max-width: 768px) {
  .software-skill-inline {
    flex-basis: 50%; /* 2 items per row */
  }
}

@media (max-width: 576px) {
  .software-skill-inline {
    flex-basis: 1%; /* 2 item per row */
  }
}
